import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "../components/Core";
import Seo from "../components/Seo/Seo";

import HeroWhy from "../components/Rebrand/Hero/HeroWhy";
import ChooseYou from "../components/Rebrand/ChooseYou/ChooseYou";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import TrustBoxCarousel from "../components/TrustBoxCarousel"
import routers from './../assets/image/rebrand/png/routers.png'
import jeremy from './../assets/image/rebrand/jpg/jeremy.jpg'
import placeholder from './../assets/image/rebrand/png/why-network.png'
import GlobalContext from './../context/GlobalContext';

const WhyYouFibre = () => {
    const gContext = useContext(GlobalContext);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('registrationData')
            gContext.goResetRegistrationData()
        }
    }, [])

    return (
        <>
            <Seo page="why-youfibre" />
            <HeroWhy />
            <ChooseYou secondBackground />
            <Section>
                <Container>
                    <Row className="mb-5">
                        <Col md={12} lg={6}>
                            <h2 className="mb-5">Full-on fibre</h2>
                        </Col>
                        <Col md={12} lg={6}>
                            <p className="mb-3">
                                We provide Full-On Fibre. Where every bit of the Internet we bring you is fibre,
                                from start to finish. So many short change you with half-full fibre.
                                Where part of the connection is proper fibre but the rest is slow old copper wiring.
                                Which is a bit dishonest to be honest.
                            </p>
                            <p className="mb-3">
                                Full fibre is the future, a technology that is guaranteed to see users through
                                the 2025 Switch Off and beyond.
                            </p>
                            <p>
                                A pure fibre connection is capable of achieving gigabit speeds,
                                making it the fastest internet technology available today.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h2 className="mb-5 d-block d-lg-none">
                                YOU WANT FAST?<br />YOU GET FAST
                            </h2>
                        </Col>
                        <Col md={12} lg={4}>
                            <p className="mb-3">
                                The most important thing on the internet? Speed. Getting to what you want pronto.
                                You want it yesterday. Lots of providers use the term ‘ultra fast’. Really they’re ultra dodgy,
                                with no ability to deliver the speeds they promise. Not us. Blink and you miss our ultra fast service.
                            </p>
                            <p>
                                We’ll say this next bit in slow motion - just so you totally get it: UUULLLTTTRRRAAAAA AAAFFFOOORRRDDDDAAABBBLEEE.
                            </p>
                            <p>
                                Call us old fashioned, but we’ve got this quirky belief that everyone deserves access to full-on Internet. Ultra affordable, ultra fast, ultra you. Believe.
                            </p>
                        </Col>
                        <Col md={12} lg={{ span: 6, offset: 2 }} className="d-none d-lg-block">
                            <h2 className="mb-5">
                                YOU WANT FAST?<br />YOU GET FAST
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="light-green-bg">
                <Container>
                    <Row>
                        <Col md={12} lg={6}>
                            <img src={routers} className="w-100 dark-bg ratio-1" />
                        </Col>
                        <Col md={12} lg={6}>
                            <h2 className="mb-5">Our routers</h2>
                            <p>So much power in these little routers. The secret is in the TrueMesh. With a mesh system, multiple meshes work together harmoniously to send connection in every direction throughout your home.</p>
                            <p>Our router technology intelligently routes traffic to reduce drop-offs and dead spots, keeping you online and accessing the fastest possible internet speeds.</p>
                            <p>Make your wifi as smart as your home.</p>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section className="dark-bg">
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <h2 className="secondary-cl">
                                THE NETWORK<br />THAT WORKS<br />TOGETHER, WORKS<br />BETTER FOR YOU
                            </h2><br />
                            <div className="left-aligned-availability">
                                <CheckAvailability center minimized={true} />
                            </div>
                            <p className="white-color">
                                A networks is only as strong as it’s weakest link.<br />
                                You don’t want weak links.<br />
                                Which is why You partner with the biggest,<br />
                                ever-reliable, super-powered, bullet-proof partners.<br />
                                You are stronger together.
                            </p>
                        </Col>
                        <Col sm={12} md={6}>
                            <img src={placeholder} className="w-100 ratio-1" />
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Section>
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={4} lg={4}>
                            <h2>Our founder<br />speaks</h2>
                        </Col>
                        <Col xs={4} sm={4} md={3} lg={2}>
                            <img src={jeremy} className="w-100 circle ratio-1" />
                        </Col>
                        <Col sm={12} md={5} lg={6}>
                            <p>
                                “My interest for computers and the Internet developed at the age of 7 when I became fascinated by how they were changing the way we learn, play, and live. I founded YouFibre because I want everybody to benefit from a truly fast and affordable Internet service to unleash their full potential. We're building a network that's capable of delivering up to (a huge) 10,000 Mbps. Feel free to reach out, I would love to hear from you, I would love to help you.”
                            </p>
                            <p>
                                — JEREMY CHELOT. FOUNDER & CEO.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <TrustBoxCarousel />
            <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
        </>
    );
};
export default WhyYouFibre;
